import {Controller} from "@hotwired/stimulus"
import axios from "axios";
import getCSRFToken from '@shopify/csrf-token-fetcher';

export default class extends Controller {

    checked_values = []

    connect() {

        if (this.element.hasAttribute('data-checked')) {
            this.checked_values = this.element.getAttribute('data-checked').split(',')
        }

        const inputs = this.element.querySelectorAll('[name]')
        for (const input of inputs) {
            input.addEventListener('click', (e) => this.check_input(e))
        }
        console.log('START', this.checked_values)
    }

    check_input(ev) {

        const name = ev.target.getAttribute("name")
        const ind = this.checked_values.indexOf(name)

        if (ind === -1) {
            this.checked_values.push(name)
            ev.target.classList.add('checked')
        } else {
            this.checked_values.splice(ind, 1)
            ev.target.classList.remove('checked')
        }

        axios.patch('/projects/toggle_filter_bar',
            {
                checked: this.checked_values.join(','),
                authenticity_token: getCSRFToken()
            }
        )
        console.log(this.checked_values)
    }
}