import {Controller} from "@hotwired/stimulus"
import Cookies from "js-cookie";

export default class extends Controller {

    connect() {
        this.element.addEventListener('click', () => {

            const lb = document.getElementById('left-bar')
            const cl = this.element.classList

            if (cl.contains('close-btn')) {
                lb.classList.add('collapsed')
                Cookies.set('left-bar-collapsed', 'true', { sameSite: 'strict' })

            } else if (cl.contains('page-module-button')) {
                if (lb.classList.contains('collapsed')) {
                    lb.classList.remove('collapsed')
                    Cookies.set('left-bar-collapsed', 'false', { sameSite: 'strict' })
                } else {
                    lb.classList.add('collapsed')
                    Cookies.set('left-bar-collapsed', 'true', { sameSite: 'strict' })
                }
            }

        })
    }

}