(function () {
    var init_circle_graph;

    init_circle_graph = function () {
        return $('[data-circle-graph]').each(function () {
            var $graph, deg, percent;
            $graph = $(this);
            percent = parseFloat($graph.data('percent'));
            deg = 360 * percent / 100;
            if (percent > 50) {
                $graph.addClass('gt-50');
            }
            $graph.find('.circle-graph-progress-fill').css('transform', 'rotate(' + deg + 'deg)');
        });
    };

    $(function () {
        return init_circle_graph();
    });

    $(document).on('turbolinks:load', function () {
        return init_circle_graph();
    });

}).call(this);