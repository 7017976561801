export function adjust_supplements_scrollable_table() {

    const wrapper = document.getElementById('supplement-scrollable-table-wrapper')

    if (wrapper) {

        const pos = wrapper.getBoundingClientRect();
        const footer = document.getElementById('buttons-footer')
        var footer_height = footer.offsetHeight

        var calculated_height = window.innerHeight - pos.top - 80 - footer_height


        // MIN HEIGHT

        const min_height = document.getElementById('sia-wrapper').offsetHeight - 30
        if (min_height > calculated_height) {
            calculated_height = min_height
        }
        
        wrapper.style.maxHeight = `${calculated_height}px`
        wrapper.style.height = 'unset'

    }


}