import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        const row = this.element;
        const wrapper = row.closest('.ressource-planning-wrapper')
        if (wrapper.offsetWidth < row.offsetWidth) {
            wrapper.querySelector('#phases-body').style.width = row.offsetWidth + 'px'
            wrapper.querySelector('.ressources-body').style.width = row.offsetWidth + 'px'
        }
    }
}