(function() {
  var init_side_menu;

  init_side_menu = function() {
    $('.side-menu-close').on('click', function() {
      $('.side-bar').addClass('hide');
      $('.side-menu-open').removeClass('hide');
      $('.main-container').removeClass('medium-9 large-10');
      return $('.header').trigger('resize');
    });
    return $('.side-menu-open').on('click', function() {
      $('.side-bar').removeClass('hide');
      $('.side-menu-open').addClass('hide');
      $('.main-container').addClass('medium-9 large-10');
      return $('.header').trigger('resize');
    });
  };

  $(function() {
    return init_side_menu();
  });

  $(document).on('turbolinks:load', function() {
    return init_side_menu();
  });

}).call(this);