import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        this.element.addEventListener('click', (ev) => this.first_click(ev))
    }

    first_click(eve) {

        if (!this.element.hasAttribute('data-asked-and-remove-now')) {
            const reveal = document.getElementById('mec_confirm_modal')
            $(reveal).foundation('open')
            const p = reveal.querySelector('p')
            const closing_date = this.element.getAttribute('data-closing-date')
            eve.preventDefault()
            eve.stopImmediatePropagation()

            p.innerHTML = p.innerHTML.replace(`%&lt;closing_date&gt;s`, closing_date)

            const action_button = reveal.querySelector('#action_button')
            action_button.addEventListener('click', () => this.delete_row_action(reveal))
        }
    }


    delete_row_action(reveal) {
        this.element.setAttribute('data-asked-and-remove-now', true)
        this.element.click()
        this.element.removeAttribute('data-asked-and-remove-now')
        $(reveal).foundation('close')
    }
}