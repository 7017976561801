(function() {
    $.fn.extend({
        confirmWithReveal: function(options) {
            let defaults, do_confirm, handler, nativeConfirm, ref, settings;
            if (options == null) {
                options = {};
            }
            defaults = {
                modal_class: 'medium',
                title: 'Are you sure?',
                title_class: '',
                body: 'This action cannot be undone.',
                body_class: '',
                password: false,
                prompt: 'Type <strong>%s</strong> to continue:',
                footer_class: '',
                ok: 'Confirm',
                ok_class: 'button',
                cancel: 'Cancel',
                cancel_class: 'button cancel'
            };
            settings = $.extend({}, defaults, options);
            nativeConfirm = (ref = $.rails) != null ? ref.confirm : void 0;
            do_confirm = function($el) {
                let confirm_button, el_options, modal, option;
                el_options = $el.data('confirm');
                if ($el.attr('data-confirm') == null) {
                    return true;
                }
                if ((typeof el_options === 'string') && (el_options.length > 0)) {
                    return (nativeConfirm || window.confirm).call(window, el_options);
                }
                option = function(name) {
                    return el_options[name] || settings[name];
                };
                modal = $("<div data-reveal class='reveal " + (option('modal_class')) + "'>\n  <h1 data-confirm-title class='" + (option('title_class')) + "'></h1>\n  <p data-confirm-body class='" + (option('body_class')) + "'></p>\n  <div data-confirm-footer class='" + (option('footer_class')) + "'>\n    <a data-confirm-cancel data-close class='" + (option('cancel_class')) + "'></a>\n  </div>\n</div>");
                confirm_button = $el.is('a') ? $el.clone() : $('<a/>');
                confirm_button.removeAttr('data-confirm').attr('class', option('ok_class')).html(option('ok')).on('click', function(e) {
                    if ($(this).prop('disabled')) {
                        return false;
                    }
                    $el.trigger('confirm.reveal', e);
                    if ($el.is('form, :input')) {
                        return $el.closest('form').removeAttr('data-confirm').submit();
                    }
                });
                modal.find('[data-confirm-title]').html(option('title'));
                modal.find('[data-confirm-body]').html(option('body'));
                modal.find('[data-confirm-cancel]').html(option('cancel'));
                modal.find('[data-confirm-footer]').prepend(confirm_button);
                modal.appendTo($('body')).foundation().foundation('open').on('closed.zf.reveal', function(e) {
                    return modal.remove();
                });
                return false;
            };
            if ($.rails) {
                $.rails.confirm = function(message, element) {
                    return do_confirm($(element));
                };
                return $(this);
            } else {
                handler = function(e) {
                    if (!(do_confirm($(this)))) {
                        e.preventDefault();
                        return e.stopImmediatePropagation();
                    }
                };
                return this.each(function() {
                    let $el;
                    $el = $(this);
                    $el.on('click', 'a[data-confirm], :input[data-confirm]', handler);
                    $el.on('submit', 'form[data-confirm]', handler);
                    return $el;
                });
            }
        }
    });

}).call(this);