(function() {
  var init_action_reveal;

  init_action_reveal = function() {
    return $('.reveal.ask-for-action').each(function(index, reveal) {
      if ($('.reveal.ask-for-action').prop('initialized')) {
        return;
      }
      $('.reveal.ask-for-action').prop('initialized', true);
      $(reveal).find('.deny-action').on('click', function(button) {
        return $(reveal).foundation('close');
      });
      $(reveal).find('.call-hidden-link').on('click', function(button) {
        var link_id;
        link_id = $(this).data('link-id');
        $("#" + link_id).find('a').click();
        return $(reveal).foundation('close');
      });
      $(reveal).find('input[type="submit"][data-close-if-empty]').on('click', function(event) {
        var form, form_field, form_field_id;
        form_field_id = $(this).data('form-field');
        if (!form_field_id) {
          return;
        }
        form = $(this).closest('form');
        form_field = $(form).find(form_field_id);
        if (!$(form_field).val()) {
          event.preventDefault();
          return $(reveal).foundation('close');
        }
      });
      if ($(reveal).hasClass('select-records-reveal')) {
        $(reveal).on('open.zf.reveal', function(event) {
          var f, input, tb;
          input = $(event.target).find('#search-input');
          input.val('');
          f = $(event.target).find('form');
          input.on('keyup', function(event) {
            var submit_btn;
            submit_btn = $('#srr-submit-button');
            return submit_btn.click();
          });
          tb = $(reveal).find('tbody').html('');
          input.focus();
          return $('#select_records_reveal_submit').prop('disabled', true);
        });
        return $(reveal).on('closed.zf.reveal', function(event) {
          var form;
          form = document.querySelector('form.search-input');
          return form.reset();
        });
      }
    });
  };

  $(function() {
    return init_action_reveal();
  });

  $(document).on('turbolinks:load', function() {
    return init_action_reveal();
  });

}).call(this);