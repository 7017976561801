var init_action_chart, init_action_charts, on_scroll_chart;

import * as echarts from 'echarts';

init_action_chart = function(e, year, series) {
  var myChart, option;
  myChart = echarts.init(e);
  option = {
    title: {
      text: year,
      textStyle: {
        fontSize: '1.2rem'
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['geplant', 'verbraucht'],
      itemHeight: 0,
      show: false
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: gon.x_axis_labels
    },
    yAxis: {
      type: 'value',
      show: true
    },
    series: series
  };
  return myChart.setOption(option);
};

on_scroll_chart = function(element) {
  var left;
  left = $(element).scrollLeft();
  $('#hours-chart-container').css('margin-left', -left + 'px');
  return console.log('scrolling' + $(element).scrollLeft());
};

init_action_charts = function() {

  var scroller, series, wrap, wrap2, wrapper;
  wrapper = document.getElementById('workload-reports-wrapper');
  if (wrapper) {
    if ($(wrapper).prop('initialized')) {
      return;
    }
    $(wrapper).prop('initialized', true);
    wrap = document.getElementById('hours-chart-wrapper');
    series = [
      {
        name: 'geplant',
        type: 'line',
        stack: 'a',
        data: gon.planned_hours,
        lineStyle: {
          color: '#000'
        },
        itemStyle: {
          color: '#000'
        }
      }, {
        name: 'verbraucht',
        type: 'line',
        stack: 'b',
        data: gon.reported_hours,
        lineStyle: {
          color: '#E96F17'
        },
        itemStyle: {
          color: '#E96F17'
        }
      }
    ];
    init_action_chart(wrap, '', series);
    wrap2 = document.getElementById('costs-chart-wrapper');
    series = [
      {
        name: 'geplant',
        type: 'line',
        stack: 'a',
        data: gon.planned_costs,
        lineStyle: {
          color: '#000'
        },
        itemStyle: {
          color: '#000'
        }
      }, {
        name: 'verbraucht',
        type: 'line',
        stack: 'b',
        data: gon.reported_costs,
        lineStyle: {
          color: '#E96F17'
        },
        itemStyle: {
          color: '#E96F17'
        }
      }
    ];
    init_action_chart(wrap2, '', series);
    scroller = document.getElementById('chart-scroller');
    console.log('inited');
    return $(scroller).on('scroll', function() {
      return on_scroll_chart(scroller);
    });
  }
};

$(function() {
  return init_action_charts();
});

$(document).on('turbolinks:load', function() {
  return init_action_charts();
});