window.workload_planning_check_rough_panning_month = function () {

    const target = event.target
    const month_key = target.getAttribute('data-month')
    const row = target.closest('.staff-planning-fields')
    const input = row.querySelector('.rough-months')
    const months = input.value.split(',').filter(value => value !== '')

    if (target.classList.contains('checked')) {

        target.classList.remove('checked')
        const m = months.filter(value => value !== month_key)
        input.value = m.join(',')

    } else {

        target.classList.add('checked')
        months.push(month_key)
        input.value = months.join(',')

    }
}