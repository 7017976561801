import {Controller} from "@hotwired/stimulus"
import * as jquery from 'jquery'

export default class extends Controller {

    connect() {
        const rm_btn = this.element.querySelector('a')
        const users_hint = this.element.dataset.users
        const projects_hint = this.element.dataset.projects
        console.log('HINTS', users_hint, projects_hint)
        if (users_hint || projects_hint) {
            console.log('add-listener!')
            rm_btn.addEventListener('click', (event) => this.click(event, users_hint, projects_hint))
        }
    }

    click(event, users_hint, projects_hint) {
        console.log('clicked!')

        const msg = []
        if (users_hint) {
            msg.push(users_hint)
        }
        if (projects_hint) {
            msg.push(projects_hint)
        }
        console.log(msg)


        if (!confirm(msg.join(' '))) {
            event.stopPropagation()
        }
    }
}