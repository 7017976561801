import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        const select = this.element.getElementsByTagName('select')[0]
        select.addEventListener('change', () => this.handle_change(select))
        select.setAttribute('data-value-backup', select.value)

        const path = this.element.getAttribute('data-path')
    }

    handle_change(select) {
        const path = this.element.getAttribute('data-path')
        const desired_value = select.value
        if (desired_value === 'true') {
            axios.get(path)
                .then(res => {
                    if (!res.data.lockable) {

                        this.show_reveal(res.data)

                    }
                })
        }
        console.log('change handled for', select)
    }

    show_reveal(data) {
        const reveal = document.getElementById('confirm-action-reveal')
        const action_button = reveal.querySelector('#confirm_button')
        //action_button.addEventListener('click', () => this.apply_change(reveal))
        const cancel_button = reveal.querySelector('#cancel-button')

        // https://ticket.sedlmair.ch/issues/1922#note-7
        // => Wenn bis 31.08.24 die Anforderung nicht wieder geändert wurde, kann der Teil des Codes entfernt werden.
        //cancel_button.addEventListener('click', () => this.cancel_changes(reveal))
        action_button.addEventListener('click', () => this.cancel_changes(reveal))
        action_button.innerHTML = data.confirm_button_label
        cancel_button.classList.add('hide')

        reveal.querySelector('#title').innerHTML = data.reveal_title
        const content = reveal.querySelector('#content')
        content.innerHTML = data.reveal_body


        // open the reveal
        $(reveal).foundation('open')
    }

    cancel_changes(reveal) {
        const select = this.element.getElementsByTagName('select')[0]
        select.value = false
        this.cleanup_reveal(reveal)
        $(reveal).foundation('close')
    }

    apply_change(reveal) {
        const select = this.element.getElementsByTagName('select')[0]
        select.value = true
        //$(reveal).foundation('close')
        this.cleanup_reveal(reveal)
    }

    cleanup_reveal(reveal) {
        const content = reveal.querySelector('#content')
        content.innerHTML = '<p id="body" class="body">custom-message</p>'

        const action_button = reveal.querySelector('#confirm_button')
        action_button.removeEventListener('click', () => this.apply_change(reveal))
        const cancel_button = reveal.querySelector('#cancel-button')
        cancel_button.removeEventListener('click', () => this.cancel_changes(reveal))
        cancel_button.classList.remove('hide')
    }

}