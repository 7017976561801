import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        this.handle_change_value()
        this.element.addEventListener('change', () => this.handle_change_value())
        this.element.addEventListener('keyup', () => this.handle_change_value())
    }

    handle_change_value() {
        const el = this.element.parentElement.closest('.select-default-value-wrapper')
        const el2 = el.querySelector('.default-value')
        console.log('LOG:', el, this.element.value)
        if (this.element.value === '') {
            el2.style.display = 'block'
        } else {
            el2.style.display = 'none'
        }
    }

}