(function() {
  var init_sia_inputs;

  init_sia_inputs = function() {
    return $('input[name*="sub_contractor[release_status"]').off().on('change', function(event) {
      var my_input, other_input;
      my_input = $(event.target);
      other_input = $("input[name='" + (my_input.data('input-to-disable')) + "'");
      if (my_input.is(':checked')) {
        return other_input.attr('disabled', false);
      } else {
        other_input.val('0.0');
        return other_input.attr('disabled', 'disabled');
      }
    });
  };

  $(function() {
    return init_sia_inputs();
  });

  $(document).on('turbolinks:load', function() {
    return init_sia_inputs();
  });

}).call(this);