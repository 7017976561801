import {Controller} from "@hotwired/stimulus"
import AutoNumeric from 'autonumeric'


export default class extends Controller {

    connect() {
        var digits = 2
        if (this.element.hasAttribute('data-digits')) {
            digits = this.element.getAttribute('data-digits')
        }
        const anElement = new AutoNumeric(this.element, {
            currencySymbol : '%',
            currencySymbolPlacement: 's',
            decimalCharacter : '.',
            digitGroupSeparator : "",
            decimalPlaces: digits,
        });
    }
}