(function() {
  //var cocoon_after_add_workload, cocoon_confirm, delete_row_confirmed_action, delete_row_show_reveal, init_action_cocoon;
  var cocoon_confirm, delete_row_confirmed_action, delete_row_show_reveal, init_action_cocoon;

  cocoon_confirm = function(e, insertedItem, originalEvent) {
    var confirmation;
    confirmation = confirm('Das Teammitglied ' + insertedItem[0].querySelector('.initials').innerHTML + ' ' + insertedItem[0].querySelector('.name').innerHTML + " aus dem Projekt entfernen?");
    if (confirmation === false) {
      return e.preventDefault();
    }
  };


  init_action_cocoon = function() {
   // $('#staff-members-wrapper,#staff-members-wrapper-2').off('cocoon:before-remove', cocoon_confirm).on('cocoon:before-remove', cocoon_confirm);
    //$('#cocoon-workload-container').off('cocoon:after-insert', cocoon_after_add_workload).on('cocoon:after-insert', cocoon_after_add_workload);
  };

  $(function() {
    return init_action_cocoon();
  });

  $(document).on('turbolinks:load', function() {
    return init_action_cocoon();
  });

}).call(this);