export function adjust_scrollable_table() {

    // assuming the scrollable table is at the bottom of the view

    const wrapper = document.getElementById('scrollable-table-wrapper')

    if (wrapper) {

        const pos = wrapper.getBoundingClientRect();
        var footer_height = 0.0
        if (wrapper.hasAttribute('data-footer-height')) {
            footer_height = parseFloat(wrapper.getAttribute('data-footer-height'))
        }

        var calculated_height = window.innerHeight - pos.top - 20 - footer_height


        // MIN HEIGHT

        var min_height = null
        if (wrapper.hasAttribute('data-min-height')) {
            min_height = parseFloat(wrapper.getAttribute('data-min-height'))
            if (min_height < calculated_height) {
                calculated_height = min_height
            }
        }



        wrapper.style.maxHeight = `${calculated_height}px`
        wrapper.style.height = 'unset'

    }


}