import $ from 'jquery';

// infinite scrolling
const infinite_scrolling = function () {
    $(window).off('scroll')
    let more_jobs_url;
    more_jobs_url = $('.pagination a[rel="next"]').attr('href');
    if (more_jobs_url) {
        if ($(window).scrollTop() > $(document).height() - $(window).height() - 60) {
            $('.pagination').html('Loading ...');
            $.getScript(more_jobs_url);
        }
        else {
            $(window).on('scroll', infinite_scrolling);
        }
    }
}

// copy job UUID to clipboard
const init_jobs = function (){
    $('.jobs .job_id').on('click', function () {
        let title = $(this).data('job');
        navigator.clipboard.writeText(title).then((value) => {
            console.log('job id copied');
        });
    });

    if ($('#infinite-scrolling').length > 0) {
        $(window).on('scroll', infinite_scrolling);
    }
};

$(function () {
    init_jobs();
});

$(document).on('turbolinks:load', init_jobs);