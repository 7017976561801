(function() {
  var init_pages, show_tree, show_variants;

  show_tree = function() {
    var nspace, page_tree, page_url;
    page_tree = $('#page_tree');
    if (page_tree.length === 0 || page_tree.data('initialized')) {
      return;
    }
    page_tree.data('initialized', true);
    page_url = page_tree.data('page-url');
    nspace = ' &nbsp; ';
    page_tree.tree({
      dragAndDrop: true,
      autoEscape: false,
      saveState: true,
      autoOpen: true,
      onCreateLi: function(node, $li) {
        var del_link, edit_link, icon, id, links, menu_icon_classes, url, view_link;
        id = node.id;
        url = page_url + '/' + id;
        edit_link = '<a href="' + url + '/edit" >Bearbeiten</a>';
        view_link = '<a href="' + node.url + '" target="_blank">Anzeigen</a>';
        if (node['deletable']) {
          del_link = nspace + '<a href="' + url + '" data-confirm="Sind Sie sicher, dass Sie die Page &laquo;' + node.title + '&raquo; löschen möchten?" data-method="delete" rel="nofollow" title="Diese Seite dauerhaft entfernen">Löschen</a>';
        } else {
          del_link = '';
        }
        links = del_link + nspace + edit_link + nspace + view_link;
        links = '<span class="actions right">' + links + '</span>';
        $li.find('.jqtree-title').after(links);
        menu_icon_classes = node.menu_icon_classes;
        icon = '';
        if (menu_icon_classes) {
          icon = "<div class=\"" + (menu_icon_classes.join(' ')) + "\"></div>";
          $li.find('.jqtree-title').before(icon + '&nbsp;');
        }
        return true;
      },
      onCanSelectNode: function(node) {
        var sel;
        sel = $('#page_tree').tree('isNodeSelected', node);
        return !sel;
      }
    });
    return page_tree.bind('tree.move', function(e) {
      var csrf_token = document.querySelector('head meta[name="csrf-token"]').getAttribute('content')
      var info;
      info = e.move_info;
      return $.post(page_url + '/move', {
        moved: info.moved_node.id,
        target: info.target_node.id,
        position: info.position,
        authenticity_token: csrf_token
      });
    });
  };

  show_variants = function(that) {
    var kind;
    that.find('.variants').hide();
    kind = that.find('.page_page_parts_kind select').val();
    if (kind > '') {
      that.find("." + kind).show();
    }
    return that.find('.select2').select2();
  };

  init_pages = function() {
    show_tree();
    $(document).on('change', '#new_edit_locale', function() {
      return $(this).closest('form').submit();
    });
    $('#page-parts').on('cocoon:after-insert', function(e, item) {
      var part;
      part = $(item);
      part.find('.variants').hide();
      return document.dispatchEvent(new Event('init_ckeditor'));
    });
    $('#page-parts .nested-fields').each(function(idx, item) {
      return show_variants($(this));
    });
    return $('#page-parts').on('change', '.page_page_parts_kind select', function() {
      var that;
      that = $(this);
      return show_variants(that.closest('.nested-fields'));
    });
  };

  $(function() {
    return init_pages();
  });

  $(document).on('turbolinks:load', function() {
    return init_pages();
  });

}).call(this);