(function() {
  var init_file_upload_btn, init_form_cancel_button, init_blank_target;

  init_file_upload_btn = function() {
    if ($('.file-upload-container').length > 0) {
      return $('.file-upload-container input[type="file"]').on('change', function(event) {
        var file_input, input_container, selected_file_label;
        file_input = $(event.target);
        input_container = file_input.closest('.file-upload-container');
        selected_file_label = input_container.find('.selected-file-label');
        if (file_input.val()) {
          return selected_file_label.text(file_input.val().split('\\').pop());
        } else {
          return selected_file_label.text(selected_file_label.data('label'));
        }
      });
    }
  };

  init_form_cancel_button = function() {
    if ($('.cancel-form-button').length > 0) {
      return $('.cancel-form-button').on('click', function(event) {
        let forms = document.getElementsByTagName('form');
        for (let i = 0; i < forms.length; i++) {
          forms[i].reset()
        }
      });
    }
  };

  init_blank_target = function () {
    $('.blank-target').click(function(e) {
      e.preventDefault(); //prevents the default submit action
      $(this).closest('form').attr('target', '_blank').submit();
    });
  }

  $(function() {
    init_file_upload_btn();
    return init_form_cancel_button();
  });

  $(document).on('turbolinks:load', function() {
    init_file_upload_btn();
    init_blank_target();
    return init_form_cancel_button();
  });

}).call(this);