(function() {
    var init_select2;

    $(document).on('turbolinks:load', function() {
        return init_select2();
    });

    $(document).ready(function() {
        return init_select2();
    });

    init_select2 = function() {
        $('select.select2, article.form select[multiple]').select2();
        $("select").on("select2:select", function(evt) {
            var $element, element;
            if (!evt.params) {
                return;
            }
            element = evt.params.data.element;
            $element = $(element);
            $element.detach();
            $(this).append($element);
            return $(this).trigger("change");
        });
        return $('.select2_array_text_input').each(function() {
            var element, searchElement;
            element = $(this).select2({
                tags: true,
                tokenSeparators: [',', ' '],
                dropdownCssClass: 'hide'
            });

            /* select elements on clicking
             */
            $(this).on('select2:closing', function(e) {
                var current, value;
                value = $(e.target.nextSibling).find('.select2-search input').val();
                current = element.val() || [];
                current.push(value);
                element.val(current);
                $(this).trigger('change');
                return $(this).trigger('select2:select');
            });

            /* hack to allow only a given number of values
             */
            if ($(this).data('max-values')) {
                searchElement = null;
                $(this).on('select2:select', function(e) {
                    if (e.target.childElementCount === $(this).data('max-values')) {
                        searchElement = $(e.target.nextSibling).find('.select2-search');
                        return searchElement.hide();
                    }
                });
                return $(this).on('select2:unselect', function(e) {
                    if (searchElement) {
                        return searchElement.show();
                    }
                });
            }
        });
    };

}).call(this);