(function() {
  var exclusive_rates, init_guarantee_reserve, init_project_summary, on_change_project_definite_open;

  init_guarantee_reserve = function() {
    return $('input[name="project[guarantee_reserve]"]').off().on('change', function(event) {
      var input_guarantee_reserve, input_reason_no_reserve;
      input_guarantee_reserve = $(event.target);
      input_reason_no_reserve = $('input[name="project[reason_no_reserve]"]');
      if (input_guarantee_reserve.val() === 'true') {
        input_reason_no_reserve.val('');
        return input_reason_no_reserve.attr('disabled', 'disabled');
      } else {
        return input_reason_no_reserve.attr('disabled', false);
      }
    });
  };

  exclusive_rates = function() {
    $('.mean input').on('change', function(event) {
      var that;
      that = $(this);
      if (that.val() > 0) {
        $('.single input').val(0.0);
      }
    });
    $('.single input').on('change', function(event) {
      if ($(this).val() > 0) {
        $('.mean input').val(0.0);
      }
    });
  };

  on_change_project_definite_open = function(el) {
    var lab, number, rev, url, url_new;
    lab = $(el.target).find(':selected').text();
    number = $(el.target).val();
    rev = $('#confirm-definite-reveal');
    url = rev.find('a.confirm_button').attr("href");
    url_new = url.split('?')[0] + '?definite_open=' + number;
    rev.find('a.confirm_button').attr("href", url_new);
    return rev.foundation('open');
  };

  init_project_summary = function() {
    var e, sel;
    e = $('#contents-wrapper.management.summary');
    if (e) {
      sel = $('select#definite_open');
      return sel.on('change', function(sel) {
        return on_change_project_definite_open(sel);
      });
    }
  };

  $(function() {
    init_guarantee_reserve();
    return exclusive_rates();
  });

  $(document).on('turbolinks:load', function() {
    init_guarantee_reserve();
    exclusive_rates();
    return init_project_summary();
  });

}).call(this);