import {Controller} from "@hotwired/stimulus"


export default class extends Controller {

    connect() {
        this.element.addEventListener('click', (ev) => this.first_click(ev))
    }

    first_click(eve) {

        if (!this.element.hasAttribute('data-asked-and-remove-now')) {
            const reveal = document.getElementById('mec_confirm_all_modal')
            $(reveal).foundation('open')
            eve.preventDefault()
            eve.stopImmediatePropagation()

            const action_button = reveal.querySelector('#action_button')
            action_button.addEventListener('click', () => this.delete_row_action(reveal))
        }
    }


    delete_row_action(reveal) {
        this.element.setAttribute('data-asked-and-remove-now', true)
        this.element.click()
        this.element.removeAttribute('data-asked-and-remove-now')
        $(reveal).foundation('close')
    }
}