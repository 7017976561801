import {Controller} from "@hotwired/stimulus"
import axios from "axios";
import getCSRFToken from '@shopify/csrf-token-fetcher';

export default class extends Controller {

    connect() {
        this.element.addEventListener('click', () => this.toggle_favorite())
        this.add_open_dropdown_listeners()
    }

    toggle_favorite() {
        const el = this.element
        axios.patch(
            `/projects/${this.element.getAttribute('data-project-id')}/toggle_favorite`,
            {authenticity_token: getCSRFToken()}
        ).then(function (response) {
                if (response.data === 'active') {
                    el.classList.add('active')
                } else {
                    el.classList.remove('active')
                }
            })
    }

    add_open_dropdown_listeners() {
        const cell = this.element.closest('.cell')
        const dropdown = cell.getElementsByClassName('dropdown-button')[0]
        dropdown.addEventListener('before-open-panel', () => cell.classList.add('active'))
        dropdown.addEventListener('after-close-panel', () => cell.classList.remove('active'))
    }
}