import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.element.addEventListener('click', () => this.toggle_password())
    }

    toggle_password() {
        const input = document.getElementById('user_password')
        if (input.getAttribute("type") === "password") {
            input.setAttribute('type', 'text')
        } else {
            input.setAttribute('type', 'password')
        }

        const conf = document.getElementById('user_password_confirmation')
        if (conf) {
            if (conf.getAttribute("type") === "password") {
                conf.setAttribute('type', 'text')
            } else {
                conf.setAttribute('type', 'password')
            }
        }

        const wrapper = this.element.closest('.password-wrapper')
        const inp = wrapper.querySelector('input')
        inp.focus()
    }
}