import {Controller} from "@hotwired/stimulus"
import {Jodit} from 'jodit'

export default class extends Controller {

    connect() {
        Jodit.make(this.element,
            {
                imageProcessor: {
                    replaceDataURIToBlobIdInView: false
                },
                //"buttons": "bold,italic"
                "buttons": "bold,italic,underline,strikethrough,ul,ol,image,hr,table,link,indent,outdent,left"
            }
        );
    }
}